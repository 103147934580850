exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-ceos-guide-jsx": () => import("./../../../src/pages/a-ceos-guide.jsx" /* webpackChunkName: "component---src-pages-a-ceos-guide-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-ai-and-ml-jsx": () => import("./../../../src/pages/ai-and-ml.jsx" /* webpackChunkName: "component---src-pages-ai-and-ml-jsx" */),
  "component---src-pages-ai-future-jsx": () => import("./../../../src/pages/ai-future.jsx" /* webpackChunkName: "component---src-pages-ai-future-jsx" */),
  "component---src-pages-app-development-jsx": () => import("./../../../src/pages/app-development.jsx" /* webpackChunkName: "component---src-pages-app-development-jsx" */),
  "component---src-pages-appdevelopment-jsx": () => import("./../../../src/pages/appdevelopment.jsx" /* webpackChunkName: "component---src-pages-appdevelopment-jsx" */),
  "component---src-pages-arc-one-jsx": () => import("./../../../src/pages/arc-one.jsx" /* webpackChunkName: "component---src-pages-arc-one-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-data-and-bi-jsx": () => import("./../../../src/pages/data-and-bi.jsx" /* webpackChunkName: "component---src-pages-data-and-bi-jsx" */),
  "component---src-pages-data-bi-why-jsx": () => import("./../../../src/pages/data-bi-why.jsx" /* webpackChunkName: "component---src-pages-data-bi-why-jsx" */),
  "component---src-pages-difference-between-trad-ai-and-gen-ai-jsx": () => import("./../../../src/pages/difference-between-trad-ai-and-gen-ai.jsx" /* webpackChunkName: "component---src-pages-difference-between-trad-ai-and-gen-ai-jsx" */),
  "component---src-pages-digital-experience-jsx": () => import("./../../../src/pages/digital-experience.jsx" /* webpackChunkName: "component---src-pages-digital-experience-jsx" */),
  "component---src-pages-foro-case-studies-jsx": () => import("./../../../src/pages/foro-case-studies.jsx" /* webpackChunkName: "component---src-pages-foro-case-studies-jsx" */),
  "component---src-pages-future-proof-jsx": () => import("./../../../src/pages/future-proof.jsx" /* webpackChunkName: "component---src-pages-future-proof-jsx" */),
  "component---src-pages-git-hub-copilot-by-orlando-almario-jsx": () => import("./../../../src/pages/git-hub-copilot-by-orlando-almario.jsx" /* webpackChunkName: "component---src-pages-git-hub-copilot-by-orlando-almario-jsx" */),
  "component---src-pages-giveback-program-jsx": () => import("./../../../src/pages/giveback-program.jsx" /* webpackChunkName: "component---src-pages-giveback-program-jsx" */),
  "component---src-pages-health-jsx": () => import("./../../../src/pages/health.jsx" /* webpackChunkName: "component---src-pages-health-jsx" */),
  "component---src-pages-heath-jsx": () => import("./../../../src/pages/heath.jsx" /* webpackChunkName: "component---src-pages-heath-jsx" */),
  "component---src-pages-homestory-jsx": () => import("./../../../src/pages/homestory.jsx" /* webpackChunkName: "component---src-pages-homestory-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovate-jsx": () => import("./../../../src/pages/innovate.jsx" /* webpackChunkName: "component---src-pages-innovate-jsx" */),
  "component---src-pages-insight-2-profit-jsx": () => import("./../../../src/pages/insight2profit.jsx" /* webpackChunkName: "component---src-pages-insight-2-profit-jsx" */),
  "component---src-pages-modernize-jsx": () => import("./../../../src/pages/modernize.jsx" /* webpackChunkName: "component---src-pages-modernize-jsx" */),
  "component---src-pages-our-outsourcing-guide-jsx": () => import("./../../../src/pages/our-outsourcing-guide.jsx" /* webpackChunkName: "component---src-pages-our-outsourcing-guide-jsx" */),
  "component---src-pages-our-resources-jsx": () => import("./../../../src/pages/our-resources.jsx" /* webpackChunkName: "component---src-pages-our-resources-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-proptech-jsx": () => import("./../../../src/pages/proptech.jsx" /* webpackChunkName: "component---src-pages-proptech-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-scale-jsx": () => import("./../../../src/pages/scale.jsx" /* webpackChunkName: "component---src-pages-scale-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-sitemap-xml-jsx": () => import("./../../../src/pages/sitemap.xml.jsx" /* webpackChunkName: "component---src-pages-sitemap-xml-jsx" */),
  "component---src-pages-sleep-doctor-jsx": () => import("./../../../src/pages/sleep-doctor.jsx" /* webpackChunkName: "component---src-pages-sleep-doctor-jsx" */),
  "component---src-pages-software-people-love-jsx": () => import("./../../../src/pages/software-people-love.jsx" /* webpackChunkName: "component---src-pages-software-people-love-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-what-is-dx-jsx": () => import("./../../../src/pages/what-is-dx.jsx" /* webpackChunkName: "component---src-pages-what-is-dx-jsx" */),
  "component---src-pages-why-upstart-13-jsx": () => import("./../../../src/pages/why-upstart13.jsx" /* webpackChunkName: "component---src-pages-why-upstart-13-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

